import React from "react";

// Shared Components
import Footer from "../../components/shared/footer/Footer";
import Header from "../../components/shared/header/Header";
import Seo from "../../components/seo";

// SVG
import Backdrop from "../../images/resources/compatible-central-stations/backdrop.inline.svg";

// Images
import Train from "../../images/resources/compatible-central-stations/train.png";
import USA from "../../images/resources/compatible-central-stations/usa-central.png";
import Quick from "../../images/resources/compatible-central-stations/quick-response.png";
import AMS from "../../images/resources/compatible-central-stations/ams.png";
import AvantGuard from "../../images/resources/compatible-central-stations/avant-guard.png";
import UCC from "../../images/resources/compatible-central-stations/ucc.png";
import Rapid from "../../images/resources/compatible-central-stations/rapid.jpeg";
import AAM from "../../images/resources/compatible-central-stations/aam-logo.png";

const people = [
  {
    id: 1,
    title: "USA Central Station",
    href: "#",
    image: USA,
    iconForeground: "text-teal-700",
    iconBackground: "bg-teal-50",
  },
  {
    id: 2,
    title: "Alarm Monitoring Services",
    href: "#",
    image: AMS,
    iconForeground: "text-purple-700",
    iconBackground: "bg-purple-50",
  },
  {
    id: 3,
    title: "Rapid Response Monitoring",
    href: "#",
    image: Rapid,
    iconForeground: "text-sky-700",
    iconBackground: "bg-sky-50",
  },
  {
    id: 4,
    title: "United Central Control",
    href: "#",
    image: UCC,
    iconForeground: "text-yellow-700",
    iconBackground: "bg-yellow-50",
  },
  {
    id: 5,
    title: "AvantGuard Monitoring",
    href: "#",
    image: AvantGuard,
    iconForeground: "text-rose-700",
    iconBackground: "bg-rose-50",
  },
  {
    id: 6,
    title: "All American Monitoring",
    href: "#",
    image: AAM,
    iconForeground: "text-indigo-700",
    iconBackground: "bg-indigo-50",
  },
  {
    id: 7,
    title: "Quick Response Monitoring",
    href: "#",
    image: Quick,
    iconForeground: "text-indigo-700",
    iconBackground: "bg-indigo-50",
  },
];

export default function CompatibleCentralStations() {
  return (
    <div>
      <Seo
        title="Compatible Central Stations"
        description="Learn about central stations that are compatible with Tech Tester."
      />
      <Header />
      {/* Hero section */}
      <div className="relative bg-myGray-300">
        <div
          className={
            "relative z-10 pt-24 px-10 mx-auto max-w-6xl grid grid-cols-1 md:grid-cols-2 gap-10"
          }
        >
          <div className={"my-auto text-left "}>
            <h1 className={"md:text-5xl text-4xl text-myGray-600 font-bold"}>
              Compatible is our middle name
            </h1>
            <h3 className={"text-myGray-600 text-lg pt-10"}>
              Tech Tester is currently compatible with central stations running
              the stages&trade; alarm processing software. We utilize the
              stages&trade; dealer level API to communicate directly with your
              central station. All you need is API-enabled dealer-level
              credentials, and we do the rest!
            </h3>
          </div>
          <div className={"m-auto relative"}>
            <img className="z-10 relative" src={Train} alt="Temp Item" />
          </div>
        </div>
        <Backdrop
          className={
            "fill-myGray-250 object-scale-down absolute z-2 top-36 md:top-0 right-10 md:right-52 lg:right-96 h-80 w-auto"
          }
        />
      </div>

      {/* Alternating Feature Sections */}
      <div className="relative overflow-hidden items-center justify-center">
        <div
          aria-hidden="true"
          className="absolute z-0 inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"
        />

        <h1 className={"text-center relative z-10 mt-32 font-bold text-3xl"}>
          Coming Soon (pending agreements):
        </h1>
        <div className="max-w-6xl z-10 mx-auto my-20">
          <ul
            className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
          >
            {people.map((person) => (
              <li
                key={person.id}
                className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
              >
                <div className="w-full flex items-center justify-between p-6 space-x-6">
                  <img
                    className="w-auto h-12 border-none rounded-full flex-shrink-0"
                    src={person.image}
                    alt=""
                  />
                  <div className="flex-1 truncate">
                    <div className="flex items-center space-x-3">
                      <h3 className="text-gray-900 text-md font-medium">
                        {person.title}
                      </h3>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className={"relative z-10 my-24 px-10 mx-auto max-w-5xl"}>
          <h1 className={"text-center relative z-10 pb-6 font-bold text-3xl"}>
            Are you from a Central Station or monitoring software company
            looking to integrate with us?
          </h1>
          <h4 className={"text-center relative z-10 text-xl"}>
            Contact the team at{" "}
            <span className={"hover:underline"}>
              <a href={"mailto:support@alarmtechtester.com"}>
                support@alarmtechtester.com
              </a>
            </span>
          </h4>
        </div>
      </div>
      <Footer />
    </div>
  );
}
